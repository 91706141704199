<template>
    <ContentLoader height="250" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="28" rx="5" ry="5" width="35%" height="100" />
        <rect x="37%" y="28" rx="5" ry="5" width="63%" height="80" />
        <rect x="37%" y="112" rx="5" ry="5" width="63%" height="15" />
    </ContentLoader>
</template>
<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
